import { DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';

type getEvincedAppsMenuOptionsHelperArgs = {
	hasMfaLicense: boolean;
	hasMsdkLicense: boolean;
	hasSiteScannerLicense: boolean;
	hasWebSdkLicense: boolean;
};

export const getEvincedAppsDropdownMenuOptions = (
	licenses: getEvincedAppsMenuOptionsHelperArgs
): IDropdownMenuOption[] => {
	const { hasMfaLicense, hasMsdkLicense, hasSiteScannerLicense, hasWebSdkLicense } = licenses;
	const options: IDropdownMenuOption[] = [];

	const defaultProps = {
		type: DropdownOptionType.LINK,
		hasBottomBorder: false,
		openInNewTab: true
	};

	// TODO: refactor to loop through array of apps
	// each app will have a license flag and the rest of the props

	if (hasMfaLicense) {
		options.push({
			id: 'mfa-dashboard-url',
			url: `${process.env.MFA_DASHBOARD_URL}`,
			title: 'Mobile Flow Analyzer',
			active: false,
			...defaultProps
		});
	}
	if (hasWebSdkLicense) {
		options.push({
			id: 'web-sdk-dashboard-url',
			url: `${process.env.WEB_SDK}`,
			title: 'Web SDK',
			active: false,
			...defaultProps
		});
	}

	if (hasMsdkLicense) {
		options.push({
			id: 'mobile-sdk-dashboard-url',
			url: `${process.env.MOBILE_SDK_DASHBOARD_URL}`,
			title: 'Mobile SDKs',
			active: false,
			...defaultProps
		});
	}
	if (hasSiteScannerLicense) {
		options.push({
			id: 'site-scanner-url',
			url: `${process.env.SITE_SCANNER_URL}`,
			title: 'Site Scanner',
			active: false,
			...defaultProps
		});
	}
	options.push({
		id: 'product-hub',
		url: `${process.env.PRODUCT_HUB_URL}`,
		title: 'Product Hub',
		active: true,
		...defaultProps
	});
	return options;
};
