import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	BUTTON_TYPES,
	EvIcon,
	NewEvincedLogo,
	EvDropdownMenu,
	DropdownOptionType,
	IDropdownMenuOption,
	EvContactUsButton,
	EvAppsDropdownMenu,
	EvTitle
} from '@evinced-private/ui-common';
import RoutesHelper from '../../helpers/RoutesHelper';
import AuthenticationService from '../../services/AuthenticationService';
import TenantsService from '../../services/TenantsService';
import { hubspotAPIKey } from '../../services/analytics/HubspotService';
import useUniversalLogin from '../../hooks/useUniversalLogin';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import DevTogglesPopup from '../developer-toggles/DevTogglesPopup';
import UserIcon from '../icons/UserIcon.svg';
import HeaderNavigationBreadcrumbs from '../header-navigation-breadcrumbs/HeaderNavigationBreadcrumbs';
import { getEvincedAppsDropdownMenuOptions } from './ev-header-helper';
import { ProductType } from '../../types/Tenant';
import ResetPasswordPopup from '../reset-password-popup/ResetPasswordPopup';
import './Header.scss';

const isDevelopment = process.env.RUN_ENV === 'development';

interface IHeaderProps {
	isAuthenticatedPage: boolean;
}

const Header: FC<IHeaderProps> = ({ isAuthenticatedPage }: IHeaderProps) => {
	const history = useHistory();
	const location = useLocation();
	const [userEmail, setUserEmail] = useState('');
	const [isDevTogglesPopupOpen, setDevTogglesPopupOpen] = useState<boolean>(false);
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const [resetPasswordPopupOpened, setResetPasswordPopupOpened] = React.useState(false);
	const { isAuthenticated } = useUniversalLogin();
	const { tenant, user, hasAdminPermissions, getProductIdByType } = useUserTenant();

	const closeResetPasswordPopup = (): void => setResetPasswordPopupOpened(false);
	const openResetPasswordPopup = (): void => setResetPasswordPopupOpened(true);
	const resetPwdBtnTitle = 'Reset Password';

	const inviteUsersPath = useMemo(() => {
		return RoutesHelper.getActiveUsers();
	}, []);
	useEffect(() => {
		if (AuthenticationService.isLoggedIn() && !userEmail) {
			setUserEmail(AuthenticationService.getUserEmail());
		}
	}, [location, userEmail]);

	const updateShouldShowInviteOption = useCallback(async (): Promise<void> => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}

		const hasTenant = await TenantsService.hasTenant();
		setShouldShowInviteOption(hasTenant);
	}, [isAuthenticated, isAuthenticatedPage]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	const onLogoClick = (e): void => {
		if (e.shiftKey && e.altKey) {
			e.preventDefault();
			setDevTogglesPopupOpen(true);
		}
	};

	const renderLogo = (): JSX.Element => {
		return (
			<Link className="ev-header-logo" to="/" aria-label="Home" onClick={onLogoClick}>
				<EvIcon icon={NewEvincedLogo} />
				<div className="ev-logo-text" aria-hidden="true">
					{isDevelopment && <div className="dev-note">DEV</div>}
				</div>
			</Link>
		);
	};

	const renderUserMenu = (): JSX.Element => {
		const options: IDropdownMenuOption[] = [
			{
				id: 'email',
				title: userEmail,
				buttonProps: {
					title: userEmail,
					type: BUTTON_TYPES.ICON,
					children: userEmail
				},
				hasBottomBorder: true,
				disabled: true,
				type: DropdownOptionType.BUTTON
			},
			{
				id: 'support',
				renderOption: (accessibilityProps): JSX.Element => {
					return (
						<EvContactUsButton
							type={BUTTON_TYPES.ICON}
							formTitle="How can we help?"
							description="Please share what's on your mind and we will get back to you shortly."
							portalId={hubspotAPIKey}
							userEmail={userEmail}
							userTenant={tenant?.id}
							additionalInfo={{ isRequired: true, label: 'Message' }}
							accessibilityProps={accessibilityProps}
						/>
					);
				},
				hasBottomBorder: true,
				type: DropdownOptionType.CUSTOM
			},
			{
				id: 'logout',
				title: 'Logout',
				buttonProps: {
					title: 'Logout',
					type: BUTTON_TYPES.ICON,
					onClick: () => {
						AuthenticationService.logout();
					},
					children: 'Logout'
				},
				type: DropdownOptionType.BUTTON
			}
		];
		// render user invitation option only if the user has a tenant
		if (shouldShowInviteOption && hasAdminPermissions()) {
			options.splice(1, 0, {
				id: 'user-invitation',
				buttonProps: {
					title: 'Manage Users',
					type: BUTTON_TYPES.ICON,
					onClick: () => {
						history.push(inviteUsersPath);
					},
					children: 'Manage Users'
				},
				hasBottomBorder: true,
				type: DropdownOptionType.BUTTON
			});
		}

		// render this option only if user in not working with saml provider
		if (user && !user.isSSO) {
			options.splice(-1, 0, {
				id: 'reset-password',
				buttonProps: {
					title: resetPwdBtnTitle,
					type: BUTTON_TYPES.ICON,
					onClick: openResetPasswordPopup,
					children: resetPwdBtnTitle
				},
				hasBottomBorder: true,
				type: DropdownOptionType.BUTTON
			});
		}

		return (
			<div className="ev-user-menu">
				<EvDropdownMenu
					id="user-menu"
					triggerButtonProps={{
						type: BUTTON_TYPES.ICON,
						title: 'User Menu',
						children: <EvIcon icon={UserIcon} />
					}}
					options={options}
				/>
			</div>
		);
	};

	const getEvincedAppsMenuOptions = (): IDropdownMenuOption[] => {
		if (isAuthenticated) {
			return getEvincedAppsDropdownMenuOptions({
				hasMfaLicense: !!getProductIdByType(ProductType.MOBILE_FLOW_ANALYZER),
				hasWebSdkLicense: !!getProductIdByType(ProductType.WEB_SDK),
				hasMsdkLicense: !!getProductIdByType(ProductType.MOBILE_SDK),
				hasSiteScannerLicense: !!getProductIdByType(ProductType.SCANNER)
			});
		}

		return [];
	};

	const renderProductsDropdownMenu = (): JSX.Element => {
		if (tenant?.products?.length > 1) {
			return <EvAppsDropdownMenu apps={getEvincedAppsMenuOptions()} />;
		}
		return null;
	};

	return (
		<header className="ev-header">
			<div className="header-content">
				<div className="header-logo-and-navigation">
					{renderLogo()}
					{renderProductsDropdownMenu()}
					<EvTitle titleText="Product Hub" />
					<HeaderNavigationBreadcrumbs />
				</div>

				<div className="header-actions">
					{isAuthenticatedPage && renderUserMenu()}
					<DevTogglesPopup
						isOpen={isDevTogglesPopupOpen}
						closePopup={() => {
							setDevTogglesPopupOpen(false);
						}}
					/>
				</div>
			</div>
			<ResetPasswordPopup
				userEmail={userEmail}
				isOpen={resetPasswordPopupOpened}
				closePopup={closeResetPasswordPopup}
			/>
		</header>
	);
};

export default Header;
